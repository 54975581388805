<template>
  <b-container
    fluid
    class="py-3 px-md-2 shadow"
  >
    <b-row>
      <b-col
        cols="12"
        xl="4"
      >
        <b-card>
          <validation-observer ref="formExperiencie">
            <b-card-body>
              <validation-provider
                v-slot="{errors}"
                name="dateFrom"
                :rules="editExperiencie?'required':''"
              >
                <b-form-group
                  label-cols-lg="5"
                  label-cols-md="5"
                  label-cols-sm="4"
                  label-cols="12"
                  content-cols-lg="7"
                  content-cols-md="7"
                  content-cols-sm="8"
                  content-cols="12"
                >
                  <template #label>
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    Date From :
                  </template>
                  <kendo-datepicker
                    id="date"
                    v-model="formNewExperiencie.dateFrom"
                    v-mask="'##/##/####'"
                    :first-day-of-week="1"
                    placeholder="MM/DD/YYYY"
                    :format="'MM/dd/yyyy'"
                    :state="errors[0] ? false : null"
                    :class="errors[0] ? 'border-danger' : ''"
                    :max="new Date()"
                    class="leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date"
                    :disabled="!editExperiencie"
                  />
                </b-form-group>
              </validation-provider>

              <validation-provider
                v-slot="{errors}"
                name="dateEnd"
                :rules="editExperiencie?'required':''"
              >
                <b-form-group
                  label-cols-lg="5"
                  label-cols-md="5"
                  label-cols-sm="4"
                  label-cols="12"
                  content-cols-lg="7"
                  content-cols-md="7"
                  content-cols-sm="8"
                  content-cols="12"
                >
                  <template #label>
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    Date End :
                  </template>
                  <kendo-datepicker
                    id="date"
                    v-model="formNewExperiencie.dateEnd"
                    v-mask="'##/##/####'"
                    :first-day-of-week="1"
                    placeholder="MM/DD/YYYY"
                    :format="'MM/dd/yyyy'"
                    :state="errors[0] ? false : null"
                    :class="errors[0] ? 'border-danger' : ''"
                    :max="new Date()"
                    class="leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date"
                    :disabled="!editExperiencie"
                  />
                </b-form-group>
              </validation-provider>

              <validation-provider
                v-slot="{errors}"
                name="Role"
                :rules="editExperiencie?'required|alpha_spaces':''"
              >
                <b-form-group
                  label-cols-lg="5"
                  label-cols-md="5"
                  label-cols-sm="4"
                  label-cols="12"
                  content-cols-lg="7"
                  content-cols-md="7"
                  content-cols-sm="8"
                  content-cols="12"
                >
                  <template #label>
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    Role:
                  </template>
                  <b-form-input
                    v-model="formNewExperiencie.role"
                    placeholder="Role"
                    :state="errors[0]?false:null"
                    :disabled="!editExperiencie"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                v-slot="{errors}"
                name="Company"
                :rules="editExperiencie?'required|alpha_spaces':''"
              >
                <b-form-group
                  label-cols-lg="5"
                  label-cols-md="5"
                  label-cols-sm="4"
                  label-cols="12"
                  content-cols-lg="7"
                  content-cols-md="7"
                  content-cols-sm="8"
                  content-cols="12"
                >
                  <template #label>
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    Company :
                  </template>
                  <b-form-input
                    v-model="formNewExperiencie.company"
                    placeholder="Role"
                    :state="errors[0]?false:null"
                    :disabled="!editExperiencie"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                v-slot="{errors}"
                name="reference-number"
                :rules="editExperiencie?'required|is_numeric|max:12':''"
              >
                <b-form-group
                  label-cols-lg="5"
                  label-cols-md="5"
                  label-cols-sm="4"
                  label-cols="12"
                  content-cols-lg="7"
                  content-cols-md="7"
                  content-cols-sm="8"
                  content-cols="12"
                >
                  <template #label>
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    Reference number :
                  </template>
                  <b-form-input
                    v-model="formNewExperiencie.referenceNumber"
                    placeholder="Reference number"
                    :state="errors[0]?false:null"
                    :disabled="!editExperiencie"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                v-slot="{errors}"
                name="email"
                rules="email"
              >
                <b-form-group
                  label-cols-lg="5"
                  label-cols-md="5"
                  label-cols-sm="4"
                  label-cols="12"
                  content-cols-lg="7"
                  content-cols-md="7"
                  content-cols-sm="8"
                  content-cols="12"
                >
                  <template #label>
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    Email :
                  </template>
                  <b-form-input
                    v-model="formNewExperiencie.email"
                    placeholder="Email"
                    :state="errors[0]?false:null"
                    :disabled="!editExperiencie"
                  />
                </b-form-group>
              </validation-provider>

              <validation-provider
                v-slot="{errors}"
                name="mainFunctions"
                :rules="editExperiencie?'required||max:1000':''"
              >
                <b-form-group>
                  <template #label>
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    Main functions:
                  </template>
                  <b-form-textarea
                    id="functions"
                    v-model="formNewExperiencie.function"
                    class="text-area-box roboto-class"
                    name="text"
                    cols="30"
                    rows="10"
                    max-length="1000"
                    placeholder="Describe your functions here ... "
                    :state="errors[0]?false:null"
                    :disabled="!editExperiencie"
                  />
                  <span class="float-right">{{ formNewExperiencie.function ? formNewExperiencie.function.length: 0 }} / 1000</span>
                </b-form-group>
              </validation-provider>
              <b-button
                variant="success"
                class="w-100"
                :disabled="!editExperiencie"
                @click="addExperiencie"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="18"
                />
                Add
              </b-button>
            </b-card-body>
          </validation-observer>
        </b-card>
      </b-col>
      <b-col
        v-if="!workExperiencie.length"
        cols="12"
        xl="8"
      >
        <div
          class="d-flex justify-content-center align-items-center h-100 text-info rounded"
          :class="isDarkSkin ? 'border-light':''"
          style="font-size: 15px; letter-spacing: 1px; font-weight: bolder; min-height: 300px;"
          :style="!isDarkSkin ? 'background-color: #F4F6F6;':''"
        >
          No work experiencie
        </div>
      </b-col>
      <b-col
        v-else
        cols="12"
        xl="8"
      >
        <b-row
          class="mx-2 scroll"
          style="max-height: 550px;overflow-y: auto;"
        >
          <b-col
            v-for="(item , index) in workExperiencie"
            :key="index"
            cols="12"
            md="6"
            class="p-1"
          >
            <div
              class="w-100 py-1 px-2 rounded text-dark my-card position-relative"
            >
              <feather-icon
                v-if="editExperiencie"
                v-b-tooltip.hover.v-danger
                v-b-tooltip.hover.bottom
                title="Delete"
                icon="Trash2Icon"
                size="30"
                class="cursor-pointer rounded-circle my-icon"
                style="position: absolute; top: 15px;right: 15px;padding: 7px;"
                @click="deleteItem(index)"
              />
              <span
                class="w-100 d-flex my-1"
                style="font-size: 13px;"
                :style="isDarkSkin ? 'color: black;':''"
              >
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <b class="mr-1 text-uppercase">
                  Date From : </b>
                {{ item.dateFrom | myGlobal }}
              </span>
              <span
                class="w-100 d-flex my-1"
                style="font-size: 13px;"
                :style="isDarkSkin ? 'color: black;':''"
              >
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <b class="mr-1 text-uppercase">
                  Date End : </b>{{ item.dateEnd | myGlobal }}
              </span>
              <span
                class="w-100 d-flex my-1"
                style="font-size: 13px;"
                :style="isDarkSkin ? 'color: black;':''"
              >
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <b class="mr-1 text-uppercase">Role : </b>
                {{ item.role }}
              </span>
              <span
                class="w-100 d-flex my-1"
                style="font-size: 13px;"
                :style="isDarkSkin ? 'color: black;':''"
              >
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <b class="mr-1 text-uppercase">Company : </b>
                {{ item.company }}
              </span>
              <span
                class="w-100 d-flex my-1"
                style="font-size: 13px;"
                :style="isDarkSkin ? 'color: black;':''"
              >
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <b class="mr-1 text-uppercase">Reference Number : </b>
                {{ item.referenceNumber }}
              </span>
              <span
                class="w-100 d-flex my-1"
                style="font-size: 13px;"
                :style="isDarkSkin ? 'color: black;':''"
              >
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <b class="mr-1 text-uppercase">Email : </b>
                {{ item.email }}
              </span>
              <p
                class="w-100 d-flex flex-column p-0"
                style="font-size: 13px;"
                :style="isDarkSkin ? 'color: black;':''"
              >
                <b class="text-uppercase">
                  <feather-icon
                    icon="CalendarIcon"
                    class="mr-75"
                  />
                  My functions were :</b>
                <span
                  class="px-2 py-1"
                  :style="isDarkSkin ? 'color: black !important;':''"
                >
                  {{ item.function.length > limit && indexDescription===index ? item.function : item.function.substring(0,limit) }}
                  <template v-if="item.function.length > limit">
                    <b-button
                      v-if="indexDescription!==index"
                      size="sm"
                      variant="link"
                      class="p-0 font-weight-bolder border-bottom-primary rounded-0"
                      @click="indexDescription = index"
                    >
                      ... Show more
                    </b-button>
                    <b-button
                      v-else
                      size="sm"
                      variant="link"
                      class="p-0 font-weight-bolder border-bottom-primary rounded-0"
                      @click="indexDescription = null"
                    >
                      Hidden
                    </b-button>
                  </template>
                </span>
              </p>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row
      class="mt-1"
    >
      <b-col
        cols="12"
      >
        <template v-if="editExperiencie">
          <b-button
            variant="primary"
            class="float-right mt-1"
            :disabled="!workExperiencie.length || loadExperiencie"
            @click="saveExperiencie"
          >
            Save Information
          </b-button>
          <b-button
            variant="danger"
            class="float-right mr-1 mt-1"
            :disabled="loadExperiencie"
            @click="closeEditExperiencie"
          >
            Cancel
          </b-button>
        </template>
        <template v-else>
          <b-button
            variant="secondary"
            class="float-right mt-1"
            @click="editExperiencie = true"
          >
            Edit Information
          </b-button>
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import ProfileService from '@/service/profile/profile.service';

export default {
  data() {
    return {
      formNewExperiencie: {
        dateFrom: null,
        dateEnd: null,
        role: null,
        function: null,
        company: null,
        referenceNumber: null,
      },
      originalWorkExperiencie: [],
      workExperiencie: [],
      loadExperiencie: false,
      editExperiencie: false,
      validateForm: true,
      indexDescription: null,
      limit: 100,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async addExperiencie() {
      this.validateForm = true;
      const validate = await this.$refs.formExperiencie.validate();
      if (!validate) {
        return;
      }
      this.formNewExperiencie.dateFrom = moment(this.formNewExperiencie.dateFrom).format('YYYY-MM-DD');
      this.formNewExperiencie.dateEnd = moment(this.formNewExperiencie.dateEnd).format('YYYY-MM-DD');
      this.workExperiencie.push(this.formNewExperiencie);

      this.formNewExperiencie = {
        dateFrom: null,
        dateEnd: null,
        role: null,
        function: null,
      };
      this.validateForm = false;
    },
    async deleteItem(index) {
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) {
        return;
      }
      this.workExperiencie.splice(index, 1);
    },
    closeEditExperiencie() {
      this.formNewExperiencie = {
        dateFrom: null,
        dateEnd: null,
        role: null,
        function: null,
      };
      this.validateForm = false;
      this.editExperiencie = false;
      this.workExperiencie = this.originalWorkExperiencie;
    },
    async saveExperiencie() {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) {
          return;
        }
        this.loadExperiencie = true;
        const params = {
          user_id: this.currentUser.user_id,
          experiencie: this.workExperiencie,
        };
        const { status } = await ProfileService.updateWorkExperiencie(params);
        if (status === 200) {
          this.showSuccessSwal();
        }
        this.removePreloader();
        this.loadExperiencie = false;
        this.editExperiencie = false;
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
        console.log('error to saveExperiencie: ', error);
      }
    },
    async getData() {
      try {
        const params = {
          user_id: this.currentUser.user_id,
        };
        const { data } = await ProfileService.getWorkInformation(params);
        const dataWorkExperiencie = data.data?.[0].work_experiencie;

        if (!dataWorkExperiencie) {
          return;
        }
        const dataExperiencie = JSON.parse(dataWorkExperiencie) || [];
        this.originalWorkExperiencie = dataExperiencie.length ? dataExperiencie.map(item => ({
          dateFrom: item.dateFrom,
          dateEnd: item.dateEnd,
          role: item.role,
          function: item.function,
          company: item.company || null,
          referenceNumber: item.referenceNumber || null,
          email: item.email,
        })) : null;
        this.workExperiencie = dataWorkExperiencie ? dataExperiencie.map(item => ({
          dateFrom: item.dateFrom,
          dateEnd: item.dateEnd,
          role: item.role,
          function: item.function,
          company: item.company || null,
          referenceNumber: item.referenceNumber || null,
          email: item.email,
        })) : null;
      } catch (error) {
        console.log('error in getData: ', error);
      }
    },
  },
};
</script>
<style scoped>
.scroll::-webkit-scrollbar{
  width: 12px;
  border-radius: 10px;
  background:#CACFD2;
}
.scroll::-webkit-scrollbar-thumb{
    background-image: linear-gradient(to bottom, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    border: 1px solid #c777b9;
}
.my-card {
  background-image: radial-gradient( circle farthest-corner at 5.3% 17.2%,  rgb(208, 252, 255) 0%, rgb(134, 162, 238) 90% );
}
.my-card:hover{
  transition: 0.4s ease-in-out;
  background-image: radial-gradient( circle farthest-corner at 5.3% 17.2%,  rgb(112, 169, 243) 0%, rgb(201, 243, 245) 90% );
}
.my-icon{
  transition: 0.3s ease-in-out;
  color: red;
  text-shadow: 0px 1px 20px red;
  background-color: white;
}
.my-card:hover .my-icon{
  transform: scale(1);
  color: white;
  background-color: red;
}
</style>
