<template>
  <b-container
    fluid
    class="py-3 px-md-2 shadow"
  >
    <b-row
      v-if="haveAccount"
      class="mb-2"
    >
      <b-col
        cols="12"
        class="ml-2 ml-xl-1"
      >
        <b-button
          variant="outline-info"
          @click="openPayInfoHistory"
        >
          Payment Information History
          <tabler-icon
            id="tooltip-payment-info-history"
            class="cursor-pointer ml-1"
            icon="ListIcon"
            size="25"
          />
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <!--PAYMENT METHOD-->
      <b-col
        cols="12"
        xl="4"
      >
        <b-card>
          <validation-observer ref="formPaymentMethod">
            <b-card-body>
              <validation-provider
                v-slot="{ errors }"
                name="paymentMethod"
                :rules="editAccountInformation ? 'required' : ''"
              >
                <b-form-group
                  label-cols-lg="5"
                  label-cols-md="5"
                  label-cols-sm="4"
                  label-cols="12"
                  content-cols-lg="7"
                  content-cols-md="7"
                  content-cols-sm="8"
                  content-cols="12"
                >
                  <template #label>
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    Payment method :
                  </template>
                  <v-select
                    v-model="paymentMethod.method"
                    :options="optionsMethod"
                    :reduce="(option) => option.id"
                    label="text"
                    placeholder="--Select option--"
                    :disabled="!editAccountInformation"
                    :style="errors[0] ? 'border: 1px solid red; border-radius: 5px;' : ''"
                  />
                </b-form-group>
              </validation-provider>
              <template v-if="paymentMethod.method === 1">
                <validation-provider
                  v-slot="{ errors }"
                  name="transferType"
                  :rules="editAccountInformation ? 'required' : ''"
                >
                  <b-form-group
                    label-cols-lg="5"
                    label-cols-md="5"
                    label-cols-sm="4"
                    label-cols="12"
                    content-cols-lg="7"
                    content-cols-md="7"
                    content-cols-sm="8"
                    content-cols="12"
                  >
                    <template #label>
                      <feather-icon
                        icon="CalendarIcon"
                        class="mr-75"
                      />
                      Transfer type :
                    </template>
                    <v-select
                      v-model="paymentMethod.typeTransfer"
                      :options="optionsTransfer"
                      :reduce="(option) => option.id"
                      label="text"
                      placeholder="--Select option--"
                      :disabled="!editAccountInformation"
                      :style="errors[0] ? 'border: 1px solid red; border-radius: 5px;' : ''"
                    />
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="currency"
                  :rules="editAccountInformation ? 'required' : ''"
                >
                  <b-form-group
                    label-cols-lg="5"
                    label-cols-md="5"
                    label-cols-sm="4"
                    label-cols="12"
                    content-cols-lg="7"
                    content-cols-md="7"
                    content-cols-sm="8"
                    content-cols="12"
                  >
                    <template #label>
                      <feather-icon
                        icon="CalendarIcon"
                        class="mr-75"
                      />
                      Currency :
                    </template>
                    <v-select
                      v-model="paymentMethod.typeCurrency"
                      :options="optionsCurrency"
                      :reduce="(option) => option.id"
                      label="text"
                      placeholder="--Select option--"
                      :disabled="!editAccountInformation"
                      :style="errors[0] ? 'border: 1px solid red; border-radius: 5px;' : ''"
                    />
                  </b-form-group>
                </validation-provider>
              </template>
            </b-card-body>
          </validation-observer>
        </b-card>
      </b-col>
      <template v-if="paymentMethod.method === 1">
        <!--ACCOUNT IN SOLES-->
        <b-col
          cols="12"
          xl="4"
        >
          <b-card
            class="rounded border-primary"
            :style="!isDarkSkin ? 'background-color: #EBF5FB !important;' : ''"
          >
            <template #header>
              <h4 class="text-primary">
                S/ Account Information in Soles
              </h4>
            </template>
            <validation-observer ref="formAccountSoles">
              <b-card-body class="text-white">
                <validation-provider
                  v-slot="{ errors }"
                  name="bankNameSoles"
                  :rules="paymentMethod.typeCurrency === 'PEN' ? 'required' : ''"
                >
                  <b-form-group
                    label-cols-lg="5"
                    label-cols-md="5"
                    label-cols-sm="4"
                    label-cols="12"
                    content-cols-lg="7"
                    content-cols-md="7"
                    content-cols-sm="8"
                    content-cols="12"
                  >
                    <template #label>
                      <feather-icon
                        icon="CalendarIcon"
                        class="mr-75"
                      />
                      Bank Name :
                    </template>
                    <v-select
                      v-model="accountSoles.bank"
                      :options="optionsBanks"
                      :reduce="(option) => option.id"
                      label="name"
                      placeholder="--Select option--"
                      :disabled="!editAccountInformation"
                      :class="!isDarkSkin ? 'bg-white' : ''"
                      class="rounded "
                      :style="errors[0] ? 'border: 1px solid red; border-radius: 5px;' : ''"
                    />
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="accountTypeSoles"
                  :rules="accountSoles.bank ? 'required' : ''"
                >
                  <b-form-group
                    label-cols-lg="5"
                    label-cols-md="5"
                    label-cols-sm="4"
                    label-cols="12"
                    content-cols-lg="7"
                    content-cols-md="7"
                    content-cols-sm="8"
                    content-cols="12"
                  >
                    <template #label>
                      <feather-icon
                        icon="CalendarIcon"
                        class="mr-75"
                      />
                      Account Type :
                    </template>
                    <v-select
                      v-model="accountSoles.typeAccount"
                      :options="optionsTypeAccount"
                      :reduce="(option) => option.id"
                      label="name"
                      placeholder="--Select option--"
                      class="rounded "
                      :style="errors[0] ? 'border: 1px solid red; border-radius: 5px;' : ''"
                      :disabled="!accountSoles.bank || !editAccountInformation"
                    />
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="accountNumberSoles"
                  :rules="accountSoles.bank ? 'required|is_numeric' : ''"
                >
                  <b-form-group
                    label-cols-lg="5"
                    label-cols-md="5"
                    label-cols-sm="4"
                    label-cols="12"
                    content-cols-lg="7"
                    content-cols-md="7"
                    content-cols-sm="8"
                    content-cols="12"
                  >
                    <template #label>
                      <feather-icon
                        icon="CalendarIcon"
                        class="mr-75"
                      />
                      Account number :
                    </template>
                    <b-form-input
                      v-model="accountSoles.number"
                      placeholder="Account number"
                      :style="errors[0] ? 'border: 1px solid red; border-radius: 5px;' : ''"
                      :disabled="!accountSoles.bank || !editAccountInformation"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="cciSoles"
                  :rules="accountSoles.bank ? 'required|is_numeric' : ''"
                >
                  <b-form-group
                    label-cols-lg="5"
                    label-cols-md="5"
                    label-cols-sm="4"
                    label-cols="12"
                    content-cols-lg="7"
                    content-cols-md="7"
                    content-cols-sm="8"
                    content-cols="12"
                  >
                    <template #label>
                      <feather-icon
                        icon="CalendarIcon"
                        class="mr-75"
                      />
                      CCI :
                    </template>
                    <b-form-input
                      v-model="accountSoles.cci"
                      placeholder="CCI"
                      :disabled="!accountSoles.bank || !editAccountInformation"
                      :style="errors[0] ? 'border: 1px solid red; border-radius: 5px;' : ''"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-card-body>
            </validation-observer>
            <template
              v-if="paymentMethod.typeCurrency === 'PEN'"
              #footer
            >
              <h4 class="text-danger w-100 d-flex justify-content-center align-items-center font-weight-bolder">
                Currency Account
              </h4>
              <span class="font-small-4 w-100 d-flex justify-content-center align-items-center "> The selected account will be used for your monthly payments</span>
            </template>
          </b-card>
        </b-col>
        <!--ACCOUNT IN DOLLARS-->
        <b-col
          cols="12"
          xl="4"
        >
          <b-card
            class="rounded border-success"
            :style="!isDarkSkin ? 'background-color: #EAFAF1 !important;' : ''"
          >
            <template #header>
              <h4 class="text-success">
                $ Account Information in Dollars
              </h4>
              <span class="font-small-4 w-100 d-flex justify-content-start align-items-start "> The selected account will be used for your commissions</span>
            </template>
            <validation-observer ref="formAccountDollars">
              <b-card-body>
                <validation-provider
                  v-slot="{ errors }"
                  name="bankNameDollar"
                  :rules="paymentMethod.typeCurrency === 'USD' ? 'required' : ''"
                >
                  <b-form-group
                    label-cols-lg="5"
                    label-cols-md="5"
                    label-cols-sm="4"
                    label-cols="12"
                    content-cols-lg="7"
                    content-cols-md="7"
                    content-cols-sm="8"
                    content-cols="12"
                  >
                    <template #label>
                      <feather-icon
                        icon="CalendarIcon"
                        class="mr-75"
                      />
                      Bank Name :
                    </template>
                    <v-select
                      v-model="accountDollar.bank"
                      :options="optionsBanks"
                      :reduce="(option) => option.id"
                      label="name"
                      placeholder="--Select option--"
                      class="rounded "
                      :class="!isDarkSkin ? 'bg-white' : ''"
                      :style="errors[0] ? 'border:1px solid red;border-radius: 5px;' : ''"
                      :disabled="!editAccountInformation"
                    />
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="accountTypeDollar"
                  :rules="accountDollar.bank ? 'required' : ''"
                >
                  <b-form-group
                    label-cols-lg="5"
                    label-cols-md="5"
                    label-cols-sm="4"
                    label-cols="12"
                    content-cols-lg="7"
                    content-cols-md="7"
                    content-cols-sm="8"
                    content-cols="12"
                  >
                    <template #label>
                      <feather-icon
                        icon="CalendarIcon"
                        class="mr-75"
                      />
                      Account Type :
                    </template>
                    <v-select
                      v-model="accountDollar.typeAccount"
                      :options="optionsTypeAccount"
                      :reduce="(option) => option.id"
                      label="name"
                      placeholder="--Select option--"
                      :disabled="!accountDollar.bank || !editAccountInformation"
                      class="rounded "
                      :class="!isDarkSkin ? 'bg-white' : ''"
                      :style="errors[0] ? 'border:1px solid red;border-radius: 5px;' : ''"
                    />
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="accountNumberDollar"
                  :rules="accountDollar.bank ? 'required|is_numeric' : ''"
                >
                  <b-form-group
                    label-cols-lg="5"
                    label-cols-md="5"
                    label-cols-sm="4"
                    label-cols="12"
                    content-cols-lg="7"
                    content-cols-md="7"
                    content-cols-sm="8"
                    content-cols="12"
                  >
                    <template #label>
                      <feather-icon
                        icon="CalendarIcon"
                        class="mr-75"
                      />
                      Account number :
                    </template>
                    <b-form-input
                      v-model="accountDollar.number"
                      placeholder="Account number"
                      :disabled="!accountDollar.bank || !editAccountInformation"
                      :style="errors[0] ? 'border:1px solid red;border-radius: 5px;' : ''"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="accountNumberDollar"
                  :rules="accountDollar.bank ? 'required|is_numeric' : ''"
                >
                  <b-form-group
                    label-cols-lg="5"
                    label-cols-md="5"
                    label-cols-sm="4"
                    label-cols="12"
                    content-cols-lg="7"
                    content-cols-md="7"
                    content-cols-sm="8"
                    content-cols="12"
                  >
                    <template #label>
                      <feather-icon
                        icon="CalendarIcon"
                        class="mr-75"
                      />
                      CCI :
                    </template>
                    <b-form-input
                      v-model="accountDollar.cci"
                      placeholder="CCI"
                      :disabled="!accountDollar.bank || !editAccountInformation"
                      :style="errors[0] ? 'border:1px solid red;border-radius: 5px;' : ''"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-card-body>
            </validation-observer>
            <template
              v-if="paymentMethod.typeCurrency === 'USD'"
              #footer
            >
              <h4 class="text-danger w-100 d-flex justify-content-center align-items-center font-weight-bolder">
                Currency Account
              </h4>
              <span class="font-small-4 w-100 d-flex justify-content-center align-items-center "> The selected account will be used for your monthly payments</span>
            </template>
          </b-card>
        </b-col>
      </template>
    </b-row>
    <b-row>
      <b-col
        v-if="dateAvailable"
        cols="12"
      >
        <template v-if="editAccountInformation">
          <b-button
            variant="primary"
            class="float-right mt-1"
            :disabled="loadAccountInformation"
            @click="saveAccountInformation"
          >
            Save Information
          </b-button>
          <b-button
            variant="danger"
            class="float-right mt-1 mr-1"
            :disabled="loadAccountInformation"
            @click="cancelEditAccountInformation"
          >
            Cancel
          </b-button>
        </template>
        <template v-else>
          <b-button
            variant="secondary"
            class="float-right mt-1"
            @click="editAccountInformation = true"
          >
            Edit Information
          </b-button>
        </template>
      </b-col>
      <b-col
        v-else
        cols="12"
        class="border-top"
      >
        <h4 class="mt-3 text-center text-warning">
          For payment processing reasons, you cannot make modifications to your accounts until <strong class="ml-1"> {{ paymentDate }}</strong>
        </h4>
      </b-col>
    </b-row>
    <!--modal pay information-->
    <pay-info-history
      v-if="modalPayInfoModal.show"
      :employee_id="modalPayInfoModal.employee_id"
      @close="modalPayInfoModal.show = false"
    />
  </b-container>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import EmployeesService from '@/views/management/views/employees/employees.service';
import ProfileService from '@/service/profile/profile.service';
import PayInfoHistory from '@/views/management/views/employees/employees-module/employees-list/modals/options/job-details/modals/PayInfoHistory/PayInfoHistory.vue';

export default {
  components: {
    PayInfoHistory,
  },
  data() {
    return {
      paymentMethod: {
        method: null,
        typeTransfer: null,
        typeCurrency: null,
      },
      accountSoles: {
        bank: null,
        typeAccount: null,
        number: null,
        cci: null,
        preferred: null,
        currency: 'PEN',
      },
      accountDollar: {
        bank: null,
        typeAccount: null,
        number: null,
        cci: null,
        preferred: null,
        currency: 'USD',
      },
      optionsMethod: [
        { id: 1, text: 'Transfer' },
        { id: 2, text: 'Deposit' },
        { id: 3, text: 'Cash' },
        { id: 4, text: 'Check' },
      ],
      optionsTransfer: [
        { id: 'Direct', text: 'Direct' },
        { id: 'Interbank', text: 'Interbank' },
      ],
      optionsCurrency: [
        { id: 'USD', text: '$ (Dollar)' },
        { id: 'PEN', text: 'S./ (Soles)' },
      ],
      optionsBanks: [],
      optionsTypeAccount: [
        { id: 1, name: 'Current' },
        { id: 2, name: 'Master' },
        { id: 3, name: 'Savings' },
      ],
      editAccountInformation: false,
      loadAccountInformation: false,
      originalData: [],
      paymentDay: 5,
      haveAccount: false,
      modalPayInfoModal: {
        show: false,
        employee_id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    dateAvailable() {
      /* -- the last day of the month is the last day of the month, so from the first day of the
      current month until the day of payment you can't make any changes --*/
      const currentDay = moment().format('DD');
      if (currentDay >= 1 && currentDay <= this.paymentDay) {
        return false;
      }
      return true;
    },
    paymentDate() {
      return moment(`${this.paymentDay}`, 'D').format('LL');
    },
  },
  watch: {
    'paymentMethod.typeCurrency': function (newVal) {
      if (newVal === 'USD') {
        this.accountSoles.preferred = 0;
        this.accountDollar.preferred = 1;
      } else if (newVal === 'PEN') {
        this.accountSoles.preferred = 1;
        this.accountDollar.preferred = 0;
      }
    },
  },
  async mounted() {
    await this.getAllBanks();
    await this.getData();
  },
  methods: {
    async getAllBanks() {
      try {
        const data = await EmployeesService.getAllBanks();

        // eslint-disable-next-line no-param-reassign

        this.optionsBanks = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.optionsBanks;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async saveAccountInformation() {
      try {
        let othersForms = true;
        const validateFormPayment = await this.$refs.formPaymentMethod.validate();

        if (this.paymentMethod.method === 1) {
          const validateFormAccountSoles = await this.$refs.formAccountSoles.validate();
          const validateFormAccountDollar = await this.$refs.formAccountDollars.validate();

          othersForms = validateFormAccountSoles && validateFormAccountDollar;
        }

        if (!validateFormPayment || !othersForms) {
          return;
        }

        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) {
          return;
        }

        this.addPreloader();
        const params = {
          method: this.paymentMethod.method,
          typeTransfer: this.paymentMethod.typeTransfer,
          typeCurrency: this.paymentMethod.typeCurrency,
          user_id: this.currentUser.user_id,
          accounts: [
            this.accountSoles,
            this.accountDollar,
          ],
          employee: this.currentUser.fullName,
        };
        const { status } = await ProfileService.updateAccountInformation(params);
        if (status === 200) {
          this.showSuccessSwal();
          this.editAccountInformation = false;
          this.haveAccount = true;
        }
        this.removePreloader();
      } catch (error) {
        this.showErrorSwal();
        this.removePreloader();
        console.log('error to saveAccountInformation ', error);
      }
    },
    cancelEditAccountInformation() {
      this.editAccountInformation = false;
      this.paymentMethod = {
        method: null,
        typeTransfer: null,
        typeCurrency: null,
      };
    },
    async getData() {
      try {
        const params = {
          user_id: this.currentUser.user_id,
        };
        const { data } = await ProfileService.getAccountInformation(params);
        const dataAccounts = data.data;
        this.originalData = dataAccounts;

        if (!dataAccounts.length) {
          return;
        }

        // payment method
        this.haveAccount = true;
        this.modalPayInfoModal.employee_id = this.originalData?.[0].employee_id;
        this.paymentMethod.method = this.originalData.find(item => item.preferred === 1)?.payment_type;
        this.paymentMethod.typeTransfer = this.originalData.find(item => item.preferred === 1)?.sub_type;
        this.paymentMethod.typeCurrency = this.originalData.find(item => item.preferred === 1)?.currency;

        if (this.paymentMethod.method !== 1) {
          return;
        }
        // account in soles
        const dataAccountSoles = this.originalData.filter(item => item.payment_type === this.paymentMethod.method && item.currency === 'PEN');
        if (dataAccountSoles.length) {
          this.accountSoles.bank = dataAccountSoles[0].bank_account_id;
          this.accountSoles.typeAccount = dataAccountSoles[0].account_type;
          this.accountSoles.number = dataAccountSoles[0].account_number !== 'null' ? dataAccountSoles[0].account_number : null;
          this.accountSoles.cci = dataAccountSoles[1].account_number !== 'null' ? dataAccountSoles[1].account_number : null;
        }

        // account in dollars
        const dataAccountDollars = this.originalData.filter(item => item.payment_type === this.paymentMethod.method && item.currency === 'USD');
        if (dataAccountDollars.length) {
          this.accountDollar.bank = dataAccountDollars[0].bank_account_id;
          this.accountDollar.typeAccount = dataAccountDollars[0].account_type;
          this.accountDollar.number = dataAccountDollars[0].account_number !== 'null' ? dataAccountDollars[0].account_number : null;
          this.accountDollar.cci = dataAccountDollars[1].account_number !== 'null' ? dataAccountDollars[1].account_number : null;
        }
      } catch (error) {
        this.showErrorSwal();
        console.log('error: ', error);
      }
    },
    openPayInfoHistory() {
      this.modalPayInfoModal.show = true;
    },
  },
};
</script>
