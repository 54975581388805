<template>
  <b-container
    fluid
    class="py-3 px-md-2 shadow"
  >
    <b-row>
      <!--EMERGENCY CONTACT-->
      <b-col
        cols="12"
        md="12"
        xl="4"
      >
        <b-card
          class="p-0 rounded"
          :class="isDarkSkin ? 'border-primary' : ''"
          :style="!isDarkSkin ? 'background-color: #EBF5FB !important;' : ''"
        >
          <template #header>
            <h4 class="text-primary text-uppercase">
              <feather-icon
                icon="PhoneCallIcon"
                class="text-primary mr-1"
              />
              Emergency Contact
            </h4>
          </template>
          <validation-observer ref="formEmergencyContact">
            <b-card-body>
              <validation-provider
                v-slot="{ errors }"
                name="name"
                :rules="editInformationContact ? 'required|alpha_spaces' : ''"
              >
                <b-form-group
                  label-cols-lg="5"
                  label-cols-md="5"
                  label-cols-sm="4"
                  label-cols="12"
                  content-cols-lg="7"
                  content-cols-md="7"
                  content-cols-sm="8"
                  content-cols="12"
                >
                  <template #label>
                    <feather-icon
                      icon="UserIcon"
                      class="mr-75"
                    />
                    Name:
                  </template>

                  <b-form-input
                    v-model="emergencyContact.contactName"
                    placeholder="Name"
                    :class="!isDarkSkin ? 'bg-white':''"
                    :style="errors[0] ? 'border: 1px solid red;border-radius: 5px;' : ''"
                    :disabled="!editInformationContact"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="phone"
                :rules="editInformationContact ? 'required' : ''"
              >
                <b-form-group
                  label-cols-lg="5"
                  label-cols-md="5"
                  label-cols-sm="4"
                  label-cols="12"
                  content-cols-lg="7"
                  content-cols-md="7"
                  content-cols-sm="8"
                  content-cols="12"
                >
                  <template #label>
                    <feather-icon
                      icon="PhoneIcon"
                      class="mr-75"
                    />
                    Phone:
                  </template>

                  <b-form-input
                    v-model="emergencyContact.contactPhone"
                    v-mask="'(###)-###-###'"
                    placeholder="Phone"
                    :class="!isDarkSkin ? 'bg-white':''"
                    :style="errors[0] ? 'border: 1px solid red;border-radius: 5px;' : ''"
                    :disabled="!editInformationContact"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="relation"
                :rules="editInformationContact ? 'required' : ''"
              >
                <b-form-group
                  label-cols-lg="5"
                  label-cols-md="5"
                  label-cols-sm="4"
                  label-cols="12"
                  content-cols-lg="7"
                  content-cols-md="7"
                  content-cols-sm="8"
                  content-cols="12"
                >
                  <template #label>
                    <feather-icon
                      icon="UsersIcon"
                      class="mr-75"
                    />
                    Relation:
                  </template>

                  <v-select
                    v-model="emergencyContact.contactRelation"
                    :options="optionsRelations"
                    :reduce="(option) => option.id"
                    label="name"
                    placeholder="--Select option--"
                    :style="errors[0] ? 'border: 1px solid red;border-radius: 5px;' : ''"
                    :class="!isDarkSkin ? 'bg-white' : ''"
                    :disabled="!editInformationContact"
                  />
                </b-form-group>
              </validation-provider>
            </b-card-body>
          </validation-observer>
          <b-row>
            <b-col cols="12">
              <template v-if="editInformationContact">
                <b-button
                  variant="primary"
                  class="float-right mt-1"
                  :disabled="loadEmergencyContact"
                  @click="saveEmergencyContact"
                >
                  Save Information
                </b-button>
                <b-button
                  variant="danger"
                  class="float-right mt-1 mr-1"
                  :disabled="loadEmergencyContact"
                  @click="cancelEmergencyContact()"
                >
                  Cancel
                </b-button>
              </template>
              <template v-else>
                <b-button
                  variant="secondary"
                  class="float-right mt-1"
                  @click="editInformationContact = true"
                >
                  Edit Information
                </b-button>
              </template>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <!--ACADEMIC INFORMATION-->
      <b-col
        cols="12"
        md="12"
        xl="4"
      >
        <b-card
          class="p-0 rounded"
          :class="isDarkSkin ? 'border-success' : ''"
          :style="!isDarkSkin ? 'background-color: #F8F9F9 !important;' : ''"
        >
          <template #header>
            <h4 class="text-success text-uppercase">
              <feather-icon
                icon="BookOpenIcon"
                class="mr-1"
              />
              Academic Information
            </h4>
          </template>
          <validation-observer ref="formAcademicInformation">
            <b-card-body>
              <validation-provider
                v-slot="{ errors }"
                name="level"
                :rules="editAcademicInformation ? 'required' : ''"
              >
                <b-form-group
                  label-cols-lg="5"
                  label-cols-md="5"
                  label-cols-sm="4"
                  label-cols="12"
                  content-cols-lg="7"
                  content-cols-md="7"
                  content-cols-sm="8"
                  content-cols="12"
                >
                  <template #label>
                    <feather-icon
                      icon="BriefcaseIcon"
                      class="mr-75"
                    />
                    Level :
                  </template>

                  <v-select
                    v-model="academicInformation.level"
                    :class="!isDarkSkin ? 'bg-white' : ''"
                    :clearable="false"
                    :options="optionsLevel"
                    :reduce="(option) => option.value"
                    label="text"
                    placeholder="--Select option--"
                    :disabled="!editAcademicInformation"
                    :style="errors[0] ? 'border: 1px solid red;border-radius: 5px;' : ''"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="level"
                :rules="editAcademicInformation ? 'required|alpha_spaces|max:255' : ''"
              >
                <b-form-group
                  label-cols-lg="5"
                  label-cols-md="5"
                  label-cols-sm="4"
                  label-cols="12"
                  content-cols-lg="7"
                  content-cols-md="7"
                  content-cols-sm="8"
                  content-cols="12"
                >
                  <template #label>
                    <feather-icon
                      icon="BriefcaseIcon"
                      class="mr-75"
                    />
                    <span>University / Institute :</span>
                  </template>

                  <b-form-input
                    v-model="academicInformation.institution"
                    placeholder="University / Institute"
                    :disabled="!editAcademicInformation"
                    :class="errors[0] ? 'border-danger' : ''"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="level"
                :rules="editAcademicInformation ? 'required|alpha_spaces|max:255' : ''"
              >
                <b-form-group
                  label-cols-lg="5"
                  label-cols-md="5"
                  label-cols-sm="4"
                  label-cols="12"
                  content-cols-lg="7"
                  content-cols-md="7"
                  content-cols-sm="8"
                  content-cols="12"
                >
                  <template #label>
                    <feather-icon
                      icon="BriefcaseIcon"
                      class="mr-75"
                    />
                    Career:
                  </template>

                  <b-form-input
                    v-model="academicInformation.career"
                    placeholder="Career"
                    :disabled="!editAcademicInformation"
                    :class="errors[0] ? 'border-danger' : ''"
                  />
                </b-form-group>
              </validation-provider>
            </b-card-body>
          </validation-observer>
          <b-row>
            <b-col cols="12">
              <template v-if="editAcademicInformation">
                <b-button
                  variant="success"
                  class="float-right mt-1"
                  :disabled="loadAcademicInformation"
                  @click="updateAcademicInformation"
                >
                  Save Information
                </b-button>
                <b-button
                  variant="danger"
                  class="float-right mt-1 mr-1"
                  :disabled="loadAcademicInformation"
                  @click="cancelAcademicInformation()"
                >
                  Cancel
                </b-button>
              </template>
              <template v-else>
                <b-button
                  variant="secondary"
                  class="float-right mt-1"
                  @click="editAcademicInformation = true"
                >
                  Edit Information
                </b-button>
              </template>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <!--PENSION FUND-->
      <b-col
        cols="12"
        md="12"
        xl="4"
      >
        <b-card
          class="p-0 rounded"
          :class="isDarkSkin ? 'border-info' : ''"
          :style="!isDarkSkin ? 'background-color: #F5EEF8 !important;' : ''"
        >
          <template #header>
            <h4 class="text-info text-uppercase">
              <feather-icon
                icon="UserIcon"
                class="mr-1"
              />
              Pension Fund
            </h4>
          </template>
          <b-card-body v-if="!havePensionFund">
            <validation-observer ref="formPensionFund">
              <b-form-group
                label-cols-lg="5"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  I am registered in
                </template>
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="selectedTypePensionFund"
                  style="margin-top: 5px;"
                  :options="optionsTypePensionFund"
                  name="options-registered-pension-fund"
                  :disabled="statusPensionFund !== null || !editPensionFund"
                  @change="clickTypePensionFund"
                />
              </b-form-group>
              <!--Employee: No have Pension fund-->
              <b-form-group
                v-if="selectedTypePensionFund === 'NONE' || selectedTypePensionFund === 'AFP'"
                label-cols-lg="5"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  I want to register in
                </template>
                <b-form-radio-group
                  id="radio-group-2"
                  v-model="selectedRegisterTypePensionFund"
                  style="margin-top: 5px;"
                  :options="arrayPensionFund"
                  name="options-register-pension-fund"
                  :disabled="statusPensionFund !== null || !editPensionFund"
                  @change="clickRegisterTypePensionFund"
                />
              </b-form-group>
              <!--Employee: change pension fund-->
              <b-form-group
                v-if="selectedTypePensionFund === 'ONP'"
                label-cols-lg="5"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  staying in the onp ?
                </template>
                <b-form-radio-group
                  id="radio-group-3"
                  v-model="selectedStatyingONP"
                  style="margin-top: 5px;"
                  :options="optionsStayingTypePensionFund"
                  name="options-change-pension-fund"
                  :disabled="statusPensionFund !== null || !editPensionFund"
                  @change="clickStatyingONP"
                />
              </b-form-group>
              <!--PENSIONS FUND-->
              <validation-provider
                v-slot="{errors}"
                name="old-afp"
                :rules="selectedRegisterTypePensionFund === 'ONP' && selectedTypePensionFund === 'AFP'?'required':''"
              >
                <b-form-group
                  v-if="['AFP','ONP'].includes(selectedRegisterTypePensionFund) && selectedTypePensionFund === 'AFP'"
                  label-cols-lg="5"
                  label-cols-md="5"
                  label-cols-sm="4"
                  label-cols="12"
                  content-cols-lg="7"
                  content-cols-md="7"
                  content-cols-sm="8"
                  content-cols="12"
                >
                  <template #label>
                    <feather-icon
                      icon="UserIcon"
                      class="mr-75"
                    />
                    Old AFP
                  </template>
                  <v-select
                    v-model="oldAFP"
                    :class="!isDarkSkin ? 'bg-white' : ''"
                    :options="dataPensionsFund"
                    :reduce="(option) => option.id"
                    label="name"
                    placeholder="--Select option--"
                    :disabled="statusPensionFund !== null || !editPensionFund"
                    :style="errors[0]?'border: 1px solid red; border-radius: 5px;':''"
                  />
                  <strong
                    v-if="!statusPensionFund"
                    class="text-warning"
                  >select only if you want to change </strong>
                </b-form-group>
              </validation-provider>
              <template>
                <b-form-group
                  v-if="selectedStatyingONP === 'NO' || selectedRegisterTypePensionFund === 'AFP'"
                  label-cols-lg="5"
                  label-cols-md="5"
                  label-cols-sm="4"
                  label-cols="12"
                  content-cols-lg="7"
                  content-cols-md="7"
                  content-cols-sm="8"
                  content-cols="12"
                >
                  <template #label>
                    <feather-icon
                      icon="UserIcon"
                      class="mr-75"
                    />
                    Pension Fund
                  </template>
                  <v-select
                    v-model="selectedPensionFund"
                    :class="!isDarkSkin ? 'bg-white' : ''"
                    class="rounded"
                    :options="optionsPensionsFund"
                    :reduce="(option) => option.id"
                    label="name"
                    placeholder="--Select option--"
                    :disabled="statusPensionFund !== null || !editPensionFund"
                    :style="newPensionDifferentOldPension === false ? 'border: 1px solid red;border-radius: 5px;':''"
                  />
                  <span
                    v-if="newPensionDifferentOldPension === false"
                    class="text-danger"
                  >The new pension must be different from the old pension.</span>
                </b-form-group>
                <validation-provider
                  v-if="selectedTypePensionFund === 'AFP' && selectedRegisterTypePensionFund === 'AFP'"
                  v-slot="{errors}"
                  name="cuspp"
                  rules="required"
                >
                  <b-form-group
                    label-cols-lg="5"
                    label-cols-md="5"
                    label-cols-sm="4"
                    label-cols="12"
                    content-cols-lg="7"
                    content-cols-md="7"
                    content-cols-sm="8"
                    content-cols="12"
                    class="list-group-item-info p-1 rounded"
                  >
                    <template #label>
                      <feather-icon
                        icon="UserIcon"
                        class="mr-75"
                      />
                      CUSPP
                    </template>
                    <b-form-input
                      v-model="cuspp"
                      placeholder="cuspp"
                      :disabled="statusPensionFund !== null || !editPensionFund"
                      :class="errors[0] ? 'border-danger' : ''"
                    />
                    <span
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ errors[0] }}</span>
                  </b-form-group>
                  <div class="text-center">
                    <strong>You can search your cuspp</strong>
                    <a
                      href="https://servicios.sbs.gob.pe/ReporteSituacionPrevisional/Afil_Consulta.aspx"
                      target="_blank"
                      style="text-decoration: underline;margin-left: 5px;"
                    >Here</a>
                  </div>
                </validation-provider>
              </template>
            </validation-observer>
          </b-card-body>
          <b-card-body v-else>
            <b-form-group
              label-cols-lg="5"
              label-cols-md="5"
              label-cols-sm="4"
              label-cols="12"
              content-cols-lg="7"
              content-cols-md="7"
              content-cols-sm="8"
              content-cols="12"
            >
              <template #label>
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                I am registered in
              </template>
              <b-form-radio-group
                id="radio-group-1"
                v-model="selectedTypePensionFund"
                style="margin-top: 5px;"
                :options="arrayPensionFund"
                name="options-registered-pension-fund"
                :disabled="statusPensionFund !== null"
              />
            </b-form-group>
            <!--PENSIONS FUND-->
            <b-form-group
              v-if="havePensionFund && selectedTypePensionFund==='AFP'"
              label-cols-lg="5"
              label-cols-md="5"
              label-cols-sm="4"
              label-cols="12"
              content-cols-lg="7"
              content-cols-md="7"
              content-cols-sm="8"
              content-cols="12"
            >
              <template #label>
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                Pension Fund :
              </template>
              <v-select
                v-model="selectedPensionFund"
                :class="!isDarkSkin ? 'bg-white' : ''"
                :options="optionsPensionsFund"
                :reduce="(option) => option.id"
                label="name"
                placeholder="--Select option--"
                :disabled="statusPensionFund !== null"
              />
            </b-form-group>
            <b-form-group
              label-cols-lg="5"
              label-cols-md="5"
              label-cols-sm="4"
              label-cols="12"
              content-cols-lg="7"
              content-cols-md="7"
              content-cols-sm="8"
              content-cols="12"
            >
              <template #label>
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                CUSPP
              </template>
              <b-form-input
                v-model="cuspp"
                placeholder="cuspp"
                :disabled="statusPensionFund !== null"
              />
            </b-form-group>
          </b-card-body>
          <div
            class="mt-1 d-flex  align-items-center w-100"
            :class="statusPensionFund ? 'justify-content-between':'justify-content-end'"
          >
            <strong
              v-if="statusPensionFund"
              style="font-size: 15px;letter-spacing: 1px;"
              class="mr-1 ml-1"
              :class="colorStatus[statusPensionFund]"
            >{{ statusPensionFund }}
            </strong>
            <b-button
              v-if="!statusPensionFund"
              :disabled="!selectedPensionFund || loadPensionFund"
              variant="primary"
              @click="updatePensionFund"
            >
              Save Information
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapGetters } from 'vuex';
import EmployeesService from '@/views/management/views/employees/employees.service';
import ProfileService from '@/service/profile/profile.service';

export default {
  data() {
    return {
      optionsRelations: [
      ],
      emergencyContact: {
        contactName: null,
        contactPhone: null,
        contactRelation: null,
      },
      editInformationContact: false,
      loadEmergencyContact: null,
      academicInformation: {
        level: null,
        institution: null,
        career: null,
      },
      editAcademicInformation: false,
      loadAcademicInformation: null,
      optionsLevel: [
        {
          value: 'tecnico',
          text: 'Tecnico',
        },
        {
          value: 'universitario',
          text: 'Universitario',
        },
        {
          value: 'truncos',
          text: 'Truncos',
        },
        {
          value: 'estudiantes',
          text: 'Estudiantes',
        },

      ],
      optionsTypePensionFund: [
        {
          value: 'AFP',
          text: 'AFP',
        },
        {
          value: 'ONP',
          text: 'ONP',
        },
        {
          value: 'NONE',
          text: 'nothing',
        },
      ],
      optionsStayingTypePensionFund: [
        {
          value: 'YES',
          text: 'YES',
        },
        {
          value: 'NO',
          text: 'NO',
        },
      ],

      selectedPensionFund: null,
      cuspp: null,
      editPensionFund: false,
      loadPensionFund: null,
      selectedTypePensionFund: null,
      selectedRegisterTypePensionFund: null,
      selectedStatyingONP: null,
      selectedStatyingAFP: null,
      havePensionFund: false,
      statusPensionFund: null,
      oldAFP: null,
      dataPensionsFund: [],
      optionsPensionsFund: [],
      colorStatus: {
        PENDING: 'text-warning',
        AFFILIATED: 'text-success',
      },
    };
  },
  computed: {
    arrayPensionFund() {
      return this.optionsTypePensionFund.slice(0, 2);
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    newPensionDifferentOldPension() {
      if (!this.selectedPensionFund) {
        return true;
      }
      return this.oldAFP !== this.selectedPensionFund;
    },
  },
  async mounted() {
    await Promise.all([
      this.getAllRelations(),
      this.getAllPensionFund(),
    ]);
    await this.getData();
  },
  methods: {
    async getAllRelations() {
      try {
        const data = await EmployeesService.getAllRelations();
        this.optionsRelations = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.optionsRelations;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async getAllPensionFund() {
      try {
        const data = await EmployeesService.getAllPensionFund();

        // eslint-disable-next-line no-param-reassign

        this.dataPensionsFund = data.data.filter(item => item.name.toLowerCase() !== 'onp');
        this.optionsPensionsFund = data.data.filter(item => item.name.toLowerCase() !== 'onp');
        // Must return an array of items or an empty array if an error occurred

        return this.dataPensionsFund;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async saveEmergencyContact() {
      try {
        const validate = await this.$refs.formEmergencyContact.validate();
        if (!validate) {
          return;
        }

        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) {
          return;
        }

        this.addPreloader();
        this.loadEmergencyContact = true;
        const params = {
          user_id: this.currentUser.user_id,
          name: this.emergencyContact.contactName,
          phone: this.emergencyContact.contactPhone,
          relation_id: this.emergencyContact.contactRelation,
        };

        const { status } = await ProfileService.updateEmergencyContact(params);
        if (status === 200) {
          this.showSuccessSwal();
        }
        this.loadEmergencyContact = false;
        this.editInformationContact = false;
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.loadEmergencyContact = false;
        this.showErrorSwal();
        console.log('error to udpateEmergencyContact');
      }
    },
    cancelEmergencyContact() {
      this.emergencyContact = {
        contactName: null,
        contactPhone: null,
        contactRelation: null,
      };
      this.editInformationContact = false;
    },
    async updateAcademicInformation() {
      try {
        const validate = await this.$refs.formAcademicInformation.validate();
        if (!validate) {
          return;
        }

        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) {
          return;
        }

        this.addPreloader();
        this.loadAcademicInformation = true;
        const params = {
          level: this.academicInformation.level,
          institute_university: this.academicInformation.institution,
          career: this.academicInformation.career,
          user_id: this.currentUser.user_id,
        };
        const { status } = await ProfileService.updateAcademicInformation(params);
        if (status === 200) {
          this.showSuccessSwal();
        }
        this.loadAcademicInformation = false;
        this.editAcademicInformation = false;
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.loadAcademicInformation = false;
        this.showErrorSwal();
        console.log('error to updateAcademicInformation');
      }
    },
    cancelAcademicInformation() {
      this.academicInformation = {
        level: null,
        institution: null,
        career: null,
      };
      this.editAcademicInformation = false;
    },
    async getData() {
      try {
        const params = {
          user_id: this.currentUser.user_id,
        };
        const { data } = await ProfileService.getOtherInformation(params);
        const otherInformation = data.data?.[0];
        if (!otherInformation) {
          return;
        }
        const dataEmergencyContact = JSON.parse(otherInformation.emergency_contact);

        const dataAcademicInformation = JSON.parse(otherInformation.academic_information)?.[0];
        const dataPensionFund = JSON.parse(otherInformation.pension_fund);

        // set emergency contact
        this.emergencyContact.contactName = dataEmergencyContact?.name;
        this.emergencyContact.contactPhone = dataEmergencyContact?.phone;
        this.emergencyContact.contactRelation = dataEmergencyContact?.relation_id;
        // set academic information
        this.academicInformation.level = dataAcademicInformation?.level;
        this.academicInformation.institution = dataAcademicInformation?.institute_university;
        this.academicInformation.career = dataAcademicInformation?.career;
        // set pension fund
        this.setDataPensionFund(dataPensionFund);
      } catch (error) {
        console.log('ocurred error to getData ', error);
      }
    },
    setDataPensionFund(object) {
      if (!object.status || object.status?.toUpperCase() === 'CANCELED') {
        this.editPensionFund = true;
        return;
      }

      this.statusPensionFund = object.status?.toUpperCase() || null;
      this.cuspp = object.cuspp || null;
      // The employee have pension fund
      if (object.current_pension_fund && object.current_pension_fund === 1) {
        this.selectedTypePensionFund = 'ONP';
        this.havePensionFund = true;
        this.selectedPensionFund = 1;
        return;
      }
      if (object.current_pension_fund) {
        this.selectedTypePensionFund = 'AFP';
        this.havePensionFund = true;
        this.selectedPensionFund = object.current_pension_fund;
        return;
      }

      // The employee request pension fund

      if (!this.selectedTypePensionFund) {
        if (!object.old_pension_fund && object.new_pension_fund === 1) {
          this.selectedRegisterTypePensionFund = 'ONP';
          this.selectedTypePensionFund = 'NONE';
        } else if (!object.old_pension_fund && object.new_pension_fund) {
          this.selectedPensionFund = object.new_pension_fund;
          this.selectedRegisterTypePensionFund = 'AFP';
          this.selectedTypePensionFund = 'NONE';
        } else if (object.old_pension_fund) {
          this.selectedTypePensionFund = object.old_pension_fund === 1 ? 'ONP' : 'AFP';
          this.selectedStatyingONP = (object.old_pension_fund !== object.new_pension_fund && object.new_pension_fund !== 1) ? 'NO' : 'SI';
          this.selectedPensionFund = object.new_pension_fund;
          this.selectedRegisterTypePensionFund = object.new_pension_fund === 1 ? 'ONP' : 'AFP';
          this.oldAFP = object.old_pension_fund;
        }
      }
    },
    clickTypePensionFund() {
      this.selectedRegisterTypePensionFund = null;
      this.selectedPensionFund = null;
      this.selectedStatyingONP = null;
      this.oldAFP = null;
    },
    clickStatyingONP(newVal) {
      if (newVal === 'YES') {
        this.selectedPensionFund = 1; // id the ONP is 1
        return;
      }
      this.selectedPensionFund = null;
      this.oldAFP = null;
    },
    clickRegisterTypePensionFund(newVal) {
      if (newVal === 'ONP') {
        this.oldAFP = null;
        this.selectedPensionFund = 1; // id the ONP is 1
        return;
      }
      this.selectedPensionFund = null;
    },
    async updatePensionFund() {
      try {
        /* --EXPLAIN:
        -IF THE EMPLOYEE ALREADY REGISTERED IN PENSION FUND, JUS SET VARIABLE 'currentPensionFund AND THE VARIABLES 'oldPensionFund, newPensionFund' ARE NULL.
        -IF THE EMPLOYEE IS NOT REGISTERED IN PENSION FUND, JUS SET VARIABLE 'newPensionFund' AND THE VARIABLES 'currentPensionFund, oldPensionFund'  ARE NULL.
        -IF THE EMPLOYEE ALREADY REGISTERED IN PENSION FUND BUT HE WANTS TO CHANGE, JUS SET VARIABLES 'oldPensionFund, newPensionFund' AND THE VARIABLE 'currentPensionFund'  IS NULL.
        --*/
        const validate = await this.$refs.formPensionFund.validate();
        if (!validate) return;

        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;

        this.addPreloader();

        this.loadPensionFund = true;
        let oldPensionFund = null;
        let newPensionFund = null;
        let currentPensionFund = null;

        if (
          (this.selectedTypePensionFund === 'AFP' && this.selectedRegisterTypePensionFund === 'AFP' && !this.oldAFP)
          || (this.selectedTypePensionFund === 'ONP' && this.selectedStatyingONP === 'YES')
        ) {
          currentPensionFund = this.selectedPensionFund;
        }

        // old pension fund
        if ((this.selectedTypePensionFund === 'AFP' && this.selectedRegisterTypePensionFund === 'AFP' && this.oldAFP > 1)
            || (this.selectedTypePensionFund === 'AFP' && this.selectedRegisterTypePensionFund === 'ONP')) {
          oldPensionFund = this.oldAFP;
        } else if ((this.selectedTypePensionFund === 'ONP' && this.selectedStatyingONP === 'NO')) {
          oldPensionFund = 1;
        }

        // new pension fund
        if (
          (this.selectedTypePensionFund === 'AFP' && this.selectedRegisterTypePensionFund === 'AFP'
                                    && this.oldAFP > 1 && this.oldAFP !== this.selectedPensionFund)
        || (this.selectedTypePensionFund === 'AFP' && this.selectedRegisterTypePensionFund === 'ONP')
        || (this.selectedTypePensionFund === 'ONP' && this.selectedStatyingONP === 'NO')
        || this.selectedTypePensionFund === 'NONE') {
          newPensionFund = this.selectedPensionFund;
        }
        const params = {
          created_by: this.currentUser.user_id,
          user_id: this.currentUser.user_id,
          status: currentPensionFund ? null : 1,
          old_pension_fund: oldPensionFund,
          new_pension_fund: newPensionFund,
          current_pension_fund: currentPensionFund,
          cuspp: this.cuspp,
          employee: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
        };

        const { status } = await ProfileService.updatePensionFund(params);
        if (status === 200) {
          this.showSuccessSwal();
          this.editPensionFund = false;
          this.statusPensionFund = 'PENDING';
        }
        this.removePreloader();
        this.loadPensionFund = false;
      } catch (error) {
        this.loadPensionFund = false;
        this.removePreloader();
        this.showErrorSwal();
        console.log('error in updatePensionFund: ', error);
      }
    },
  },
};
</script>
